/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import PaymentList from './payment-list/PaymentList';
import { Button, Modal } from 'antd';
import PaymentCreate from './payment-create/PaymentCreate';
import { useDispatch, useSelector } from 'react-redux';
import { asyncGetLoosePayments, clearStates, ILoosePaymentStore } from '../../reducers/loose-payment.reducer';

const LoosePayments = () => {
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const dispatch = useDispatch();
    const loosePayments = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.payments);
    const isLoading = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.isLoading);
    const total = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.total);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        dispatch(asyncGetLoosePayments(currentPage));
    }, [currentPage]);

    function handleCloseModal () {
        setOpenCreate(false);
        dispatch(clearStates());
    };

    return (
        <section>
            <TitleSection title="Pagamentos Avulsos">
                <div>
                    <Button
                        onClick={() => setOpenCreate(true)}
                        size="large"
                        type="primary"
                    >
                        Novo pagamento avulso
                    </Button>
                </div>
            </TitleSection>

            <PaymentList
                isLoading={isLoading}
                payments={loosePayments}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                total={total}
            />

            {openCreate &&
                <Modal
                    onCancel={handleCloseModal}
                    footer={false}
                    title="Adicionar Pagamento Avulso"
                    maskClosable={false}
                    visible={true}
                    width={780}
                >
                    <PaymentCreate
                        setOpenCreate={setOpenCreate}
                    />
                </Modal>
            }
        </section >
    );
};

export default LoosePayments;
